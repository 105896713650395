
  import { Component, Vue } from 'vue-property-decorator';
  import { TeacherDto } from '@/interfaces/teacherDtos/TeacherDto';
  import { TeacherEditionDto } from '@/interfaces/teacherDtos/TeacherEditionDto';

  // types utilisés pour définir le comportement du composant
  const editType = "edit";
  const createType = 'create';

  /**
   * @desc boite de dialogue permettant de créer/modifier un ensignant
   */
  @Component({
    components: {

    },
  })
  export default class EditTeacherDialog extends Vue {
    boxWidth = "60rem";

    visible = false;
    teacher: TeacherDto = new TeacherDto();
    type = 'create'; // default value -> create

    nameInput = "";
    firstnameInput = "";
    loginInput = "";
    passwordInput = "";

    /**
     * @desc montre la boite de dialogue de edition/creation d'un enseignant
     * @param teacher : object enseignant initial
     * @param type : définit le comportement du composant, soit 'edit' soit 'create'
     */
    public show(teacher : TeacherDto, type : string) : void {
      this.teacher = teacher;

      this.nameInput = teacher.name;
      this.firstnameInput = teacher.firstname;
      this.loginInput = teacher.login || "";
      this.passwordInput = "";

      this.type = type;
      this.visible= true;
      this.$vuetify.theme.dark = false;
    }

    /**
     * @desc cache la boite de dialogue
     */
    public hide() : void {
      this.visible = false;
    }

    /**
     * @desc fonction qui valide les données rentrées, et les renvoie en emettant un événement associé
     */
    public validate() : void{
      (this.$refs.inputForm as any).validate();

      setTimeout(() => {
        if(!this.visible) return;
        let editedTeacher = this.getUpdatedData();
        if(this.type === editType) {
          if(Object.keys(editedTeacher).length <= 1){ // seulement l'id
            this.cancel();
          }
          else this.$emit('teacherValidated', editedTeacher)
        }
        else{
          this.$emit('teacherCreated', editedTeacher)
        }
      }, 200);
    }

    /**
     * @desc renvoie un object contenant toutes les données renseignées par l'utilisateur
     */
    private getUpdatedData() : TeacherEditionDto {
      let outputTeacher = new TeacherEditionDto();

      if(this.type === 'edit') { // mode édition
        outputTeacher.id = this.teacher.id;
        outputTeacher.name = this.teacher.name !== this.nameInput ? this.nameInput : undefined;
        outputTeacher.login = this.teacher.login !== this.loginInput ? this.loginInput : undefined;
        outputTeacher.firstname = this.teacher.firstname !== this.firstnameInput ? this.firstnameInput : undefined;
        outputTeacher.password = this.passwordInput ? this.passwordInput : undefined;
      }
      else { // mode création
        outputTeacher.name = this.nameInput;
        outputTeacher.firstname = this.firstnameInput;
        outputTeacher.login = this.loginInput;
        outputTeacher.password = this.passwordInput;
      }
   
      return outputTeacher;
    }

    /**
     * @desc annule les modifications apportées. Cache simplement la boite de dialogue
     */
    public cancel() : void {
      setTimeout(() => {
        this.visible = false;
      }, 200);
    }
  }

