
/**
 * @desc format de données à envoyer pour modifier un enseignant
 */
 export class TeacherEditionDto {

  constructor();
  constructor(data : any);
  constructor(data ? : any) {
    if(data && data.id)
      this.id = data.id;
    if(data && data.name)
      this.name = data.name;
    if(data && data.firstname)
      this.firstname = data.firstname;
    if(data && data.login)
      this.login = data.login;
    if(data && data.password)
      this.password = data.password;
  }

  id = 0;
  name? : string;
  firstname? : string;

  login? : string;
  password? : string;
}