import { TeacherEditionDto } from "./TeacherEditionDto";

/**
 * @desc format de données reçues correspondant à un enseignant
 */
export class TeacherDto {

  constructor();
  constructor(data : any);
  constructor(data ? : any) {
    if(data && data.id)
      this.id = data.id;
    if(data && data.name)
      this.name = data.name;
    if(data && data.firstname)
      this.firstname = data.firstname;
    if(data && data.mail)
      this.mail = data.mail;
    if(data && data.login)
      this.login = data.login;
  }

  public cloneToEditionDto() : TeacherEditionDto {
    return new TeacherEditionDto({
      id: this.id,
      name : this.name,
      firstname: this.firstname,
      login: this.login
    });
  }

  id = 0;
  name = "";
  firstname = "";
  mail = "";
  fullName? = "";
  login? : string;
}