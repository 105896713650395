import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"value":_vm.visible,"dark":false,"width":_vm.boxWidth,"persistent":""}},[_c('div',{staticClass:"overlay-body"},[_c('h2',[_vm._v(_vm._s(_vm.type === 'create' ? 'Créer un enseignant' : 'Éditer l\'enseignant'))]),_c(VForm,{ref:"inputForm"},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VTextField,{staticClass:"field",attrs:{"label":"Nom","required":_vm.type === 'create',"hide-details":""},model:{value:(_vm.nameInput),callback:function ($$v) {_vm.nameInput=$$v},expression:"nameInput"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VTextField,{staticClass:"field",attrs:{"label":"Prénom","required":_vm.type === 'create',"hide-details":""},model:{value:(_vm.firstnameInput),callback:function ($$v) {_vm.firstnameInput=$$v},expression:"firstnameInput"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VTextField,{staticClass:"field",attrs:{"label":"Identifiant","required":_vm.type === 'create',"hide-details":""},model:{value:(_vm.loginInput),callback:function ($$v) {_vm.loginInput=$$v},expression:"loginInput"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c(VTextField,{staticClass:"field",attrs:{"label":"Mot de passe","required":_vm.type === 'create',"type":"password","hide-details":""},model:{value:(_vm.passwordInput),callback:function ($$v) {_vm.passwordInput=$$v},expression:"passwordInput"}})],1)],1)],1)],1),_c('div',{staticClass:"button-list"},[_c(VBtn,{staticClass:"bottom-button",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Annuler")]),_c(VBtn,{staticClass:"bottom-button",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.validate()}}},[_vm._v("Valider")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }