
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '../../services/authentication.service';
  import {ImageTransferService} from '../../services/image-transfer.service';
  import EditTeacherDialog from '@/components/EditTeacherDialog.component.vue';
  import { TeacherEditionDto } from '@/interfaces/teacherDtos/TeacherEditionDto';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';
  import { TeacherDto } from '@/interfaces/teacherDtos/TeacherDto';


  const editTeacherType = 'edit';
  const createTeacherType = 'create';

  /**
   * @desc page de gestion des enseignants
   */
  @Component({
    components: {
      'edit-teacher-dialog' : EditTeacherDialog,
    },
  })
  export default class TeachersAdmin extends Vue 
  {
    private tableHeaders = [
      { text: '#', sortable: false },
      { text: 'Nom', value: 'name', sortable: true },
      { text: 'Prénom', value: 'firstname', sortable: true },
      { text: 'Login', value: 'login', sortable: true},
      { text: 'Actions', sortable: false },
      { text: '', sortable: false },
    ];

    private allTeachers : TeacherDto[] = [];
    selectedTeacher : TeacherDto = new TeacherDto();
    teacherEdition = false;

    editTeacherType = 'edit';

    private loading= false;
    private tableSortBy = '';
    private tableDesc = true;


    /**
     * @desc initialisation et écoute des événements émis
     */
    mounted() :void {
      if(AuthenticationService.getUserStatus() !== Consts.user_status_admin){
        this.$router.push('/');
        return;
      }
      this.loadTeachers();
    }

    /**
     * @desc charge les enseignants contenues en base de données
     */
    private loadTeachers() : void { 
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(  UrlConsts.getAllTeachersWithUsers, headers)
        .then((response) => {
          this.allTeachers = response.data;
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc appelle la boite de dialogue de création d'un enseignant
     */
    private addTeacher() : void {
      (this.$refs.editTeacherDialog as EditTeacherDialog).show(new TeacherDto(), createTeacherType);
    }

    serverDialog = false;

    /**
     * @desc requète de création d'un enseignant envoyée au serveur
     */
    private async serverCreateTeacher(teacher : TeacherEditionDto) : Promise<void> {
      if(this.serverDialog) return;
      this.serverDialog = true;
      
      const headers = AuthenticationService.getRequestHeader();
       axios
        .post(  UrlConsts.createTeacher, teacher, headers)
        .then((response) => {
          this.serverDialog = false;
          (this.$refs.editTeacherDialog as EditTeacherDialog).hide();
          const createdTeacher = new TeacherDto(response.data);
          this.allTeachers.splice(0, 0, createdTeacher);
        })
        .catch(error => this.serverError(error))
    }


    /**
     * @desc ouvre la boite de dialogue d'édition d'un enseignant
     */
    private editTeacher(teacher : TeacherDto) : void {
      if(!teacher) return;
      this.selectedTeacher = teacher;
      this.teacherEdition = true;
      (this.$refs.editTeacherDialog as EditTeacherDialog).show(teacher, editTeacherType);
    }

    /**
     * @desc requète de modification d'un enseignant envoyée au serveur
     */
    private async serverEditTeacher(teacher : TeacherEditionDto) : Promise<void> {
      if(this.serverDialog) return;
      this.serverDialog = true;

      const headers = AuthenticationService.getRequestHeader();
       axios
        .post(UrlConsts.editTeacher, teacher, headers)
        .then((response) => {
          this.serverDialog = false;
          (this.$refs.editTeacherDialog as EditTeacherDialog).hide();
          const editedTeacher = new TeacherDto(response.data);
          this.updateTeacher(editedTeacher);
        })
        .catch(error => this.serverError(error))
    }

    /**
     * @desc met à jour l'affichage d'un enseignant dans la table
     */
    private updateTeacher(data : TeacherDto) : void {
      const teacherIndex = this.allTeachers.findIndex(x => x.id === data.id);
        if(teacherIndex > -1) {
          this.allTeachers[teacherIndex] = data;
          this.allTeachers = this.allTeachers.splice(0);
        }
    }

    /**
     * @desc affiche une erreur serveur
     */
    private serverError(error : string) : void{
      console.log(error);
      this.serverDialog = false;
    }
  }
